import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { routes } from "../../../lib/routes";
import { Button } from "../../Shared/Button/Primary/Button";

export interface ShowAllBlogPostsButtonProps {
  className?: string;
}

const ShowAllBlogPostsButtonInner = (props: ShowAllBlogPostsButtonProps) => {
  const { t } = useTranslation("translations");
  return (
    <div className={props.className}>
      <Link to={routes.allBlogPosts.to}>
        <Button type="primary">{t("postsFromBlog.showAllBlogPosts")}</Button>
      </Link>
    </div>
  );
};

export const ShowAllBlogPostsButton = styled(ShowAllBlogPostsButtonInner)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
