import { graphql, useStaticQuery } from "gatsby";

export const useNLastBlogPosts = (limit = 3) => {
  const { posts, allStrapiBlog } = useStaticQuery(graphql`
    query useNLastBlogPosts {
      allStrapiBlog(sort: { fields: createdAt, order: DESC }) {
        nodes {
          content
          title
          id

          thumbnail {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const newPosts = allStrapiBlog.nodes.map((blog) => ({
    ...blog,
    teaser: blog.content.slice(0, 500),
    excerpt: blog.content.slice(0, 2000),
  }));

  console.log(newPosts);

  if (limit === -1) {
    return newPosts;
  }
  return newPosts.slice(0, limit);
};
