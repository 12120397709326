import { Container } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { useNLastBlogPosts } from "../../../Shared/hooks/useNLastBlogPosts";
import { Section } from "../../../Shared/Layout/components/Section/Section";
import { Article } from "../Article";

export interface LastArticlesProps {
  className?: string;

  limit: number;
}

const LastArticlesInner = (props: LastArticlesProps) => {
  const { limit } = props;
  const articles = useNLastBlogPosts(limit);

  return (
    <Section className={props.className}>
      <Container>
        {articles.map(article => (
          <Article
            key={article.title}
            title={article.title}
            image={article.thumbnail.childImageSharp.fluid}
            {...article}
          />
        ))}
      </Container>
    </Section>
  );
};

export const LastArticles = styled(LastArticlesInner)``;
