import Grid from "@material-ui/core/Grid";
import GatsbyImage, { FluidObject } from "gatsby-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { generatePostPath } from "../../../lib/utils";
import { ViewMoreButton } from "../../Shared/Button/ViewMore/ViewMoreButton";
import { theme } from "../../Shared/Theme/Theme";

export interface ArticleProps {
  className?: string;
  title: string;
  teaser: string;
  excerpt: string;
  content?: string;
  image: FluidObject;
}

const ArticleContent = styled(Grid)`
  background-color: ${(props) => props.theme.color.sectionBg};
  margin-top: 10px;
  margin-bottom: 30px;
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const LeftSide = styled(Grid)``;
const RightSide = styled(Grid)`
  padding: 30px;
  position: relative;

  ul {
    padding-inline-start: 20px;
  }

  li {
    color: ${(props) => props.theme.color.gray};
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ArticleInner = (props: ArticleProps) => {
  const { title, teaser, excerpt, image, html } = props;
  const { t } = useTranslation("translations");

  return (
    <Link className={props.className} to={generatePostPath(title)}>
      <ArticleContent container justify="space-between">
        <LeftSide item md={4} sm={12} xs={12}>
          <ImageWrapper>
            <GatsbyImage
              style={{
                height: "100%",
              }}
              fluid={image}
            />
          </ImageWrapper>
        </LeftSide>

        <RightSide
          item
          md={8}
          style={{
            position: "relative",
          }}
        >
          <h3>{title}</h3>
          <div className="html-re">
            <ReactMarkdown source={props.content} />
          </div>
          <div className="readmore" />
          <Actions>
            <ViewMoreButton to={generatePostPath(title)}>
              {t("buttons.viewMore")}
            </ViewMoreButton>
          </Actions>
        </RightSide>
      </ArticleContent>
    </Link>
  );
};

export const Article = styled(ArticleInner)`
  color: ${theme.color.gray};
  transition: all 230ms ease-in-out;
  display: flex;

  &:hover {
    transform: scale(1.005);
  }

  h3 {
    margin-bottom: 15px;
  }

  .html-re {
    max-height: 486px;
    overflow: hidden;
  }
  .readmore {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    height: 150px;

    background-image: linear-gradient(to bottom, transparent, #f5f5f5);
  }
`;
