import React from "react";

import Layout from "../components/Shared/Layout/Layout";
import { TopHeader } from "../components/Shared/Layout/components/TopHeader/TopHeader";
import { MainBlogSection } from "../components/Blog/MainBlogSection";
import { Container } from "@material-ui/core";
import { LastArticles } from "../components/Blog/components/LastArticles/LastArticles";
import { OrientedOnSolutionsAndPraxisSection } from "../components/Blog/components/OrientedOnSolutionsAndPraxisSection";
import { ShowAllBlogPostsButton } from "../components/Blog/components/ShowAllBlogPostsButton";
import { Section } from "../components/Shared/Layout/components/Section/Section";

const BlogPage = () => (
  <Layout>
    <TopHeader title="Blog" />
    <LastArticles limit={2} />
    <ShowAllBlogPostsButton />
    <OrientedOnSolutionsAndPraxisSection />
  </Layout>
);

export default BlogPage;
